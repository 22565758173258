import React, { useState, useEffect, useRef } from "react";

import useRefState from "../hooks/use-ref-state.js";

import MorphContextProvider from "./morph-context.js";
import TransitionStage, {
  Stage,
  IS_IN,
  HOLDING,
  NOT_IN,
} from "./transition-stage.js";

export const MorphTimings = {
  WITH_EXIT: "withExit",
  WITH_ENTER: "withEnter",
  SIMULTANEOUSLY: "simultaneously",
};

export const CANCEL = "cancel";

const areChildrenDifferent = (oldChildren, newChildren) => {
  if (oldChildren === newChildren) return false;
  if (
    React.isValidElement(oldChildren) &&
    React.isValidElement(newChildren) &&
    oldChildren.key != null &&
    oldChildren.key === newChildren.key
  ) {
    return false;
  }
  return true;
};

const ReactPageTransitions = (props) => {
  const { ENTERED, ENTERING, EXITING, MORPHING } = Stage;
  const { WITH_EXIT, WITH_ENTER, SIMULTANEOUSLY } = MorphTimings;

  const { router, children: nextChildren } = props;
  const [children, setChildren, childrenRef] = useRefState(props.children);

  const exitingRef = useRef();
  const inRef = useRef();

  const [status, setStatus] = useState(ENTERED);
  const [holdMorphs, setHoldMorphs] = useState(true);

  const [completedStages, setCompletedStages] = useState([]);

  const timeoutId = useRef();
  const maxMorphsimeoutId = useRef();

  const defaultTiming = WITH_EXIT;
  const maxWaitForMorphs = 500;
  const maxTimeForMorphs = 5000;
  const maxWaitForEnter = 5000;

  const requiredStages = {
    [ENTERED]: [],
    [WITH_EXIT]: [EXITING, MORPHING],
    [WITH_ENTER]: [EXITING, ENTERING, MORPHING],
    [SIMULTANEOUSLY]: [EXITING, MORPHING],
  };

  useEffect(() => {
    const isSubset = (subset, superset) => {
      const uniqueArr1 = subset.filter((o) => !superset.includes(o));
      if (uniqueArr1.length > 0) {
        return false;
      }
      return true;
    };

    if (status !== ENTERED && !timeoutId.current) {
      timeoutId.current = setTimeout(() => {
        changeState(ENTERED, nextChildren);
        timeoutId.current = null;
      }, maxWaitForEnter);
    }

    if (completedStages.includes(CANCEL)) {
      changeState(ENTERED, nextChildren);
      if (inRef.current) {
        inRef.current.moveStage();
      }
    }

    if (
      status !== ENTERED &&
      isSubset(requiredStages[status], completedStages)
    ) {
      changeState(ENTERED, nextChildren);
      clearInterval(timeoutId.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedStages, status]);

  const getMorphTiming = () => {
    let timing;
    const getTiming = (morphTiming) => {
      if (typeof morphTiming === "function") {
        return morphTiming(router, children);
      }
      return morphTiming;
    };

    if (childrenRef.current && childrenRef.current.type.morphTiming) {
      timing = getTiming(childrenRef.current.type.morphTiming);
      if (timing) {
        return timing;
      }
    }
    if (nextChildren && nextChildren.type.morphTiming) {
      timing = getTiming(nextChildren.type.morphTiming);
      if (timing) {
        return timing;
      }
    }
    return defaultTiming;
  };

  useEffect(() => {
    if (inRef.current) {
      inRef.current.moveStage();
    }
  }, []);

  useEffect(() => {
    clearTimeout(maxMorphsimeoutId.current);
    maxMorphsimeoutId.current = null;

    if (nextChildren === null) {
      setChildren(null);
    } else if (
      childrenRef.current !== null &&
      areChildrenDifferent(childrenRef.current, nextChildren)
    ) {
      //TODO: add bailout if page changes before (rapidly clicking back button)
      setCompletedStages([]);
      setHoldMorphs(true);
      const morphTiming = getMorphTiming();

      setStatus(morphTiming);
      switch (morphTiming) {
        case WITH_ENTER:
          break;
        case WITH_EXIT:
        case SIMULTANEOUSLY:
          setHoldMorphs(false);
          break;
        default:
          changeState(ENTERED, children.current);
          break;
      }
      maxMorphsimeoutId.current = setTimeout(() => {
        setCompletedStages((prev) => {
          if (
            prev.includes(ENTERING) ||
            prev.includes(EXITING) ||
            prev.includes(MORPHING)
          ) {
            return prev;
          }
          return [ENTERING, EXITING, MORPHING, CANCEL];
        });
      }, maxTimeForMorphs);

      const { pathname, query } = router;
      if (query.immediate) {
        // We make a copy of all current query parameters, and then just delete
        // the immediate param.
        const nextQuery = { ...query };
        delete nextQuery.immediate;
        const nextHref = { pathname, query: nextQuery };

        // Note the shallow: true.
        // This means we will re-render the page, but all state is preserved
        // and no getInitialProps will be called.
        router.replace(nextHref, nextHref, { shallow: true });

        // Immediately change our state to entered
        changeState(ENTERED, nextChildren);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextChildren]);

  const changeState = (status, children) => {
    setStatus(status);
    setChildren(children);
  };

  const addCompletedStage = (stage) => {
    setCompletedStages((prev) => [...prev, stage]);
  };

  const transitionFinished = () => {
    clearTimeout(maxMorphsimeoutId.current);
    maxMorphsimeoutId.current = null;
    setCompletedStages([]);
  };

  let content;
  if (status === ENTERED) {
    if (children) {
      content = [
        <TransitionStage
          key={children.key}
          isIn={IS_IN}
          ref={inRef}
          onEntered={() => {
            addCompletedStage(ENTERING);
          }}
        >
          {children}
        </TransitionStage>,
      ];
    }
  } else {
    content = [];
    if (children) {
      content.push(
        <TransitionStage
          key={children.key}
          isIn={NOT_IN}
          ref={exitingRef}
          onExited={() => {
            addCompletedStage(EXITING);
            status === WITH_ENTER && setHoldMorphs(false);
          }}
        >
          {children}
        </TransitionStage>
      );
    }
    if (nextChildren) {
      content.push(
        <TransitionStage
          key={nextChildren.key}
          isIn={
            status === SIMULTANEOUSLY || (status === WITH_ENTER && !holdMorphs)
              ? IS_IN
              : HOLDING
          }
          ref={inRef}
          onMorphing={() => {
            if (status === SIMULTANEOUSLY && inRef.current) {
              inRef.current.moveStage();
            }
          }}
          onEntered={() => {
            addCompletedStage(ENTERING);
          }}
        >
          {nextChildren}
        </TransitionStage>
      );
    }
  }

  return (
    <MorphContextProvider
      holdMorphs={holdMorphs}
      router={props.router}
      onMorphComplete={() => {
        addCompletedStage(MORPHING);
      }}
      maxWaitForMorphs={maxWaitForMorphs}
      completedStages={completedStages}
      transitionFinished={transitionFinished}
    >
      {content}
    </MorphContextProvider>
  );
};

export default ReactPageTransitions;
