import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useCallback,
  useRef,
  useMemo,
} from "react";

import styled from "styled-components";

const PageContainer = styled.div`
  // when we morph, we have 2 pages on the screen at the same time. This keeps the containers in the correct place
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.opacity === undefined ? 1 : props.opacity)};
`;

export const Stage = {
  MORPHING: "morphing",
  ENTERING: "entering",
  ENTERED: "entered",
  EXITING: "exiting",
  EXITED: "exited",
};

export const TransitionStageContext = React.createContext({
  stage: Stage.UNMOUNTED,
  registerSpring: () => {},
  onSpringComplete: () => {},
});

export const IS_IN = 1;
export const HOLDING = 0;
export const NOT_IN = -1;

const TransitionStage = React.forwardRef((props, ref) => {
  const { MORPHING, ENTERING, ENTERED, EXITING, EXITED } = Stage;
  // TODO: should have different timeouts for waiting for animations to start
  const maxTimeout = 10000;
  const maxWaitForAnimations = 1000;

  const [stage, setStage] = useState(MORPHING);

  const registeredSprings = useRef(new Set());

  const activeCallbacks = useRef({
    [MORPHING]: true,
    [ENTERING]: true,
    [EXITING]: true,
    springs: true,
  });

  const done = useCallback(() => {
    const safeTriggerCallback = (cb) => {
      if (activeCallbacks.current[stage]) {
        activeCallbacks.current[stage] = false;
        return cb();
      }
    };
    registeredSprings.current = new Set();

    switch (stage) {
      case MORPHING:
        return safeTriggerCallback(() => setStage(ENTERING));
      case ENTERING:
        return safeTriggerCallback(() => setStage(ENTERED));
      case EXITING:
        return safeTriggerCallback(() => setStage(EXITED));
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage, activeCallbacks.current]);

  useImperativeHandle(
    ref,
    () => ({
      moveStage: done,
    }),
    [done]
  );

  useEffect(() => {
    const inStages = [ENTERING, ENTERED];
    const exitingStages = [EXITING, EXITED];

    const { isIn } = props;
    if (isIn === IS_IN) {
      if (!inStages.includes(stage)) {
        setStage(ENTERING);
      }
    } else if (isIn === NOT_IN) {
      if (!exitingStages.includes(stage)) {
        setStage(EXITING);
      }
    } else if (isIn === HOLDING) {
      setStage(MORPHING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isIn]);

  useEffect(() => {
    switch (stage) {
      case MORPHING:
        props.onMorphing && props.onMorphing();
        break;
      case ENTERING:
      case EXITING:
        stage === EXITING && props.onExiting && props.onExiting();
        stage === ENTERING && props.onEntering && props.onEntering();

        const maxTimeoutId = setTimeout(() => {
          done();
        }, maxTimeout);
        const timeoutId = setTimeout(() => {
          if (
            activeCallbacks.current.springs &&
            registeredSprings.current.size === 0
          ) {
            clearTimeout(maxTimeoutId);
            done();
          }
        }, maxWaitForAnimations);

        return () => {
          clearTimeout(timeoutId);
          clearTimeout(maxTimeoutId);
        };
      case ENTERED:
        activeCallbacks.current.springs = true;
        props.onEntered && props.onEntered();
        break;
      case EXITED:
        props.onExited && props.onExited();
        break;
      default:
        throw new TypeError(`Unrecognized Stage: ${stage}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage]);

  const springStages = [ENTERING, EXITING];

  const registerSpring = (id) => {
    if (springStages.includes(stage)) {
      registeredSprings.current.add(id);
    }
  };

  const onSpringComplete = (id) => {
    if (springStages.includes(stage)) {
      if (registeredSprings.current.has(id)) {
        registeredSprings.current.delete(id);
        if (registeredSprings.current.size === 0) {
          //TODO: we should have a timeout for when we expect for this call to be triggered
          done();
        }
      }
    }
  };

  const providerProps = useMemo(
    () => ({
      stage,
      registerSpring,
      onSpringComplete,
      registerRef: (id, ref) => {
        props.registerRef(props.children.key, id, ref);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stage]
  );

  return (
    <TransitionStageContext.Provider value={providerProps}>
      <PageContainer opacity={stage === MORPHING || stage === EXITED ? 0 : 1}>
        {props.children}
      </PageContainer>
    </TransitionStageContext.Provider>
  );
});

export default TransitionStage;
