import React from "react";

import useLockBodyScroll from "../hooks/use-lock-body-scroll.js";

const LockBody = () => {
  useLockBodyScroll();
  return <div />;
};

export default LockBody;
