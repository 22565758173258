import React, { useReducer, useRef, useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import LockBody from "./lock-body.js";
import MorphAnimation from "./morph-animation.js";
import { CANCEL } from "./react-page-transitions.js";
import { Stage } from "./transition-stage.js";

const MorphStage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  ${(props) => !props.disableClicks && "pointer-events: none;"}

  min-height: 100%;
  z-index: ${Number.MAX_SAFE_INTEGER};
`;

export const MorphContext = React.createContext({});

const CONCAT_CHARACTER = "-DONTUSE-";

const REGISTER_REF = "REGISTER_REF";
const UPDATE_NODE_INFO = "UPDATE_NODE_INFO";
const COMPLETED_ANIMATION = "COMPLETED_ANIMATION";
const CLEAR = "CLEAR";

const morphReducer = (state, action) => {
  switch (action.type) {
    case REGISTER_REF:
      return {
        ...state,
        [`${action.id}${CONCAT_CHARACTER}${action.page}`]: {
          morphs: state[action.id] ? state[action.id].morphs : [],
          isActive: true,
          nodeHtml: action.ref.getNode().outerHTML,
          nodeLocation: action.ref.getNode().getBoundingClientRect(),
          page: action.page,
          ...action.ref,
        },
      };
    case UPDATE_NODE_INFO:
      return {
        ...state,
        ...action.info,
      };
    case CLEAR:
      return Object.keys(state)
        .filter((id) => state[id].isActive)
        .reduce((acc, id) => {
          acc[id] = {
            ...state[id],
            morphs: [],
          };
          return acc;
        }, {});
    case COMPLETED_ANIMATION:
    default:
      return state;
  }
};

const areEqSets = (a, b) => {
  if (a.length === b.length) {
    if (a.length === a.filter((e) => b.includes(e)).length) {
      if (b.length === b.filter((e) => a.includes(e)).length) {
        return true;
      }
    }
  }
  return false;
};

const MorphContextProvider = (props) => {
  const { children } = props;
  const stage = useRef(null);
  const [mounted, setMounted] = useState(false);
  const transitionInfo = useRef({});

  const [components, dispatch] = useReducer(morphReducer, {});

  const [completedMorphs, setCompletedMorphs] = useState([]);
  const [allMorphs, setAllMorphs] = useState([]);
  const [morphInfo, setMorphInfo] = useState({});
  const [morphs, setMorphs] = useState([]);

  const usedComponentIds = useRef([]);

  const getComponentIdByPage = (page) => {
    return Object.keys(components).filter(
      (key) => key.split(CONCAT_CHARACTER)[1] === page
    );
  };

  const getComponentId = ({ page, id }) => {
    return `${id}${CONCAT_CHARACTER}${page}`;
  };

  useEffect(() => {
    stage.current = document.getElementById("__next");
    setMounted(true);
  }, []);

  useEffect(() => {
    if (allMorphs.length > 0 && areEqSets(allMorphs, completedMorphs)) {
      props.onMorphComplete();
    }
    if (completedMorphs.length > allMorphs.length) {
      clearMorphs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMorphs.length, completedMorphs.length]);

  useEffect(() => {
    //TODO: this can be cleaned up
    const requestedTransitions = Object.values(morphInfo)
      .filter((o) => !o.hasStarted)
      .map(({ sourceComponentId, targetComponentId, morphId }) => ({
        sourceComponentId,
        targetComponentId,
        morphId,
      }));

    requestedTransitions.forEach(
      ({ sourceComponentId, targetComponentId, morphId }) => {
        if (components[sourceComponentId] && components[targetComponentId]) {
          // start the morph
          const getComponentLocation = (id) => {
            if (components[id].isActive) {
              return components[id].getNode().getBoundingClientRect();
            }
            return components[id].nodeLocation;
          };

          const to = getComponentLocation(targetComponentId);
          const from = getComponentLocation(sourceComponentId);

          const nodeHtml = components[sourceComponentId].isActive
            ? components[sourceComponentId].getNode().outerHTML
            : components[sourceComponentId].nodeHtml;
          const targetNodeHtml = components[targetComponentId].isActive
            ? components[targetComponentId].getNode().outerHTML
            : components[targetComponentId].nodeHtml;

          const positionCorrectors = components[sourceComponentId].isActive
            ? {
                margins: components[sourceComponentId].getMargins(),
                scrollOffset: document.documentElement.scrollTop,
              }
            : components[sourceComponentId].positionCorrectors;

          setMorphInfo((prev) => ({
            ...prev,
            [morphId]: {
              ...prev[morphId],
              hasStarted: true,
              from,
              to,
              sourceNodeHtml: nodeHtml,
              targetNodeHtml,
              nodeHtml,
              positionCorrectors,
            },
          }));
          onMorphReady({
            sourceComponentId,
            targetComponentId,
            morphId,
          });
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(components), Object.keys(morphInfo)]);

  useEffect(() => {
    const childrenKeys = React.Children.toArray(children).map((child) =>
      child.key.replace(".$", "")
    );

    if (childrenKeys.length === 2) {
      const sourcePage = childrenKeys[0];
      const targetPage = childrenKeys[1];

      const sourcePageIds = getComponentIdByPage(sourcePage).map(
        (id) => id.split(CONCAT_CHARACTER)[0]
      );
      const targetPageIds = getComponentIdByPage(targetPage).map(
        (id) => id.split(CONCAT_CHARACTER)[0]
      );

      const intersection = targetPageIds.filter((id) =>
        sourcePageIds.includes(id)
      );

      if (intersection.length > 0) {
        intersection
          .filter((id) => !usedComponentIds.current.includes(id))
          .forEach((id) => {
            usedComponentIds.current.push(id);
            registerMorphInfo({ id, sourcePage, targetPage });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(components)]);

  useEffect(() => {
    if (
      props.completedStages.includes(Stage.ENTERING) &&
      areEqSets(allMorphs, completedMorphs)
    ) {
      if (allMorphs.length > 0) {
        clearMorphs();
      }
      props.transitionFinished();
    }
    if (props.completedStages.includes(CANCEL)) {
      clearMorphs();
      props.transitionFinished();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.completedStages]);

  useEffect(() => {
    if (React.Children.count(children) === 2) {
      const id = setTimeout(() => {
        if (usedComponentIds.current.length === 0) {
          props.onMorphComplete();
        }
      }, props.maxWaitForMorphs);
      return () => {
        clearTimeout(id);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [React.Children.count(children)]);

  const captureNodeInfoByPage = (page) => {
    const updatedInfo = getComponentIdByPage(page).reduce((acc, id) => {
      const node = components[id].getNode();
      acc[id] = {
        ...components[id],
        transitionInfo: components[id].getTransitionInfo(),
        nodeLocation: node.getBoundingClientRect(),
        nodeHtml: node.outerHTML,
        positionCorrectors: {
          margins: components[id].getMargins(),
          scrollOffset: document.documentElement.scrollTop,
        },
      };
      return acc;
    }, []);

    dispatch({ type: UPDATE_NODE_INFO, info: updatedInfo });
  };

  const setComponentsInactiveByPage = (page) => {
    const updatedInfo = getComponentIdByPage(page).reduce((acc, id) => {
      const {
        onRest,
        onStart,
        positionCorrectors,
        nodeHtml,
        nodeLocation,
        hasStarted,
      } = components[id];
      acc[id] = {
        onRest,
        onStart,
        positionCorrectors,
        nodeHtml,
        nodeLocation,
        hasStarted,
        isActive: false,
      };
      return acc;
    }, []);

    dispatch({ type: UPDATE_NODE_INFO, info: updatedInfo });
  };

  const registerMorphInfo = (info) => {
    const { id, sourcePage, targetPage, config, onStart } = info;
    const sourceComponentId = getComponentId({ page: sourcePage, id });
    const targetComponentId = getComponentId({ page: targetPage, id });

    const sourceTransitionInfo = components[sourceComponentId].isActive
      ? components[sourceComponentId].getTransitionInfo()
      : components[sourceComponentId].transitionInfo;

    transitionInfo.current = {
      ...transitionInfo.current,
      ...sourceTransitionInfo,
    };

    setMorphInfo((prev) => ({
      ...prev,
      [id]: {
        hasStarted: false,
        sourceComponentId,
        targetComponentId,
        morphId: id,
        config,
        onRest: () => {
          setCompletedMorphs((prev) => [...prev, id]);
        },
        onStart: () => {
          onStart && onStart();
          components[sourceComponentId] &&
            components[sourceComponentId].onStart &&
            components[sourceComponentId].onStart();
        },
      },
    }));
  };

  const registerRef = (page, id, ref) => {
    dispatch({
      type: REGISTER_REF,
      page,
      id,
      ref,
    });
  };

  const onMorphReady = ({ morphId, sourceComponentId, targetComponentId }) => {
    setAllMorphs((prev) => [...prev, morphId]);
    // there is a chance that this has already exited
    components[sourceComponentId] &&
      components[sourceComponentId].isActive &&
      components[sourceComponentId].hide();
    components[targetComponentId] &&
      components[targetComponentId].isActive &&
      components[targetComponentId].hide();
  };

  useEffect(() => {
    if (allMorphs.length > 0) {
      setMorphs((prev) => {
        const prevIds = prev.map((o) => o.morphId);
        const newIds = allMorphs.filter((o) => !prevIds.includes(o));
        return [...prev, ...newIds.map((id) => morphInfo[id])];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMorphs.length]);

  const clearMorphs = () => {
    morphs.forEach(({ morphId, targetComponentId, onRest }) => {
      if (targetComponentId in components) {
        components[targetComponentId].isActive &&
          components[targetComponentId].show();
        components[targetComponentId].isActive &&
          components[targetComponentId].onRest &&
          components[targetComponentId].onRest();
      }
      // there is a race condition if the morph timing is simultaneous
      // because morph info is cleared before the onRest can be triggered
      onRest && onRest();
    });
    dispatch({ type: CLEAR });
    transitionInfo.current = {};
    setAllMorphs([]);
    setCompletedMorphs([]);
    setMorphInfo({});
    setMorphs([]);
    usedComponentIds.current = [];
  };

  const providerProps = useMemo(
    () => ({
      triggerMorph: registerMorphInfo,
      transitionInfo: transitionInfo.current,
      hasMorphs:
        Object.keys(morphInfo).length > 0 || React.Children.count(children) > 1,
      activeMorphs: allMorphs.length - completedMorphs.length,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transitionInfo.current, morphInfo, allMorphs, completedMorphs]
  );

  return (
    <MorphContext.Provider value={providerProps}>
      {mounted &&
        ReactDOM.createPortal(
          <MorphStage
            disableClicks={allMorphs.length > 0}
            className="morphing-stage"
          >
            {morphs.map((morph) => {
              return (
                <MorphAnimation
                  morph={morph}
                  key={morph.morphId}
                  holdMorphs={props.holdMorphs}
                  portal={stage.current}
                />
              );
            })}
            {allMorphs.length > 0 && <LockBody />}
          </MorphStage>,
          stage.current
        )}
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          registerRef,
          onExiting: () => {
            child.props.onExiting && child.props.onExiting();
            captureNodeInfoByPage(child.key);
          },
          onExited: () => {
            child.props.onExited && child.props.onExited();
            setComponentsInactiveByPage(child.key);
          },
        })
      )}
    </MorphContext.Provider>
  );
};

export default MorphContextProvider;
