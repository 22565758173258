import React, { useMemo } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

const Container = styled(animated.div)`
  position: absolute;
  transform-origin: top left;
  width: ${(props) => `${props.$width}px`};
`;

const Animation = styled(animated.div)`
  > {
    opacity: 1;
  }
`;

const MorphAnimation = ({ morph, holdMorphs }) => {
  const defaultConfig = {
    clamp: false,
    precision: 1,
    mass: 1,
    tension: 1000,
    friction: 200,
  };

  const {
    from: fromRect,
    to: toRect,
    sourceNodeHtml,
    targetNodeHtml,
    onRest,
    onStart,
    config,
    positionCorrectors: { margins, scrollOffset },
  } = morph;

  const getCoordinatesFromDomRect = (domRect) => {
    try {
      const topMargin = parseInt(margins.top.split("px")[0]);
      const leftMargin = parseInt(margins.left.split("px")[0]);
      return {
        y: domRect.y - topMargin + scrollOffset,
        x: domRect.x - leftMargin,
      };
    } catch {
      return {
        y: domRect.y + scrollOffset,
        x: domRect.x,
      };
    }
  };

  const from = useMemo(
    () => ({
      ...getCoordinatesFromDomRect(fromRect),
      width: fromRect.width,
      height: fromRect.height,
      scale: 1,
      targetOpacity: 0,
      sourceOpacity: 1,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const to = useMemo(
    () => ({
      ...getCoordinatesFromDomRect(toRect),
      width: toRect.width,
      height: toRect.height,
      scale: toRect.width / fromRect.width,
      targetOpacity: 1,
      sourceOpacity: 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [holdMorphs]
  );

  const { x, y, width, height, targetOpacity, sourceOpacity } = useSpring({
    config: { ...defaultConfig, ...config },
    ...(holdMorphs ? {} : to),
    from,
    onStart,
    onRest,
  });

  return (
    <Container
      $width={toRect.width}
      style={{
        top: y,
        left: x,
        width,
        height,
      }}
    >
      <Animation
        key="source"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          opacity: sourceOpacity,
          width,
          height,
        }}
        dangerouslySetInnerHTML={{
          __html: sourceNodeHtml,
        }}
      />
      <Animation
        key="target"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width,
          height,
          opacity: targetOpacity,
        }}
        dangerouslySetInnerHTML={{
          __html: targetNodeHtml,
        }}
      />
    </Container>
  );
};

export default MorphAnimation;
