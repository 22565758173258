import { useContext } from "react";

import { MorphContext } from "../components/morph-context.js";
import { TransitionStageContext } from "../components/transition-stage.js";

const useTransitionStage = () => {
  const transitionValues = useContext(TransitionStageContext);
  const { transitionInfo, ...morphValues } = useContext(MorphContext);

  return { ...transitionValues, ...morphValues, ...transitionInfo };
};

export default useTransitionStage;
