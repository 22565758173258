import isEqual from "lodash/isEqual";
import { useEffect, useRef, useState } from "react";
import { useSpring, useTrail } from "react-spring";

import useTransitionStage from "./use-transition-stage.js";
import useUuid from "./use-uuid.js";

export const usePageTransitionSpring = (configureSpring) => {
  const springInfo = useRegisteredSpring(configureSpring, 1);
  return useSpring(springInfo);
};

export const usePageTransitionTrail = (length, configureSpring) => {
  const springInfo = useRegisteredSpring(configureSpring, length);
  return useTrail(length, springInfo);
};
const getGoalValues = (config, current = {}) => {
  //we have to do this destructure to get the value of to
  const {
    from,
    delay,
    immediate,
    config: _,
    reset,
    reverse,
    onStart,
    onRest,
    onFrame,
    ref,
    ...to
  } = config;
  return { ...from, ...current, ...to };
};

const useRegisteredSpring = (configureSpring, length = 1) => {
  const { stage, registerSpring, onSpringComplete } = useTransitionStage();
  const [springConfig, setSpringConfig] = useState(configureSpring(stage));

  const config = useRef(getGoalValues(springConfig));

  const completedIds = useRef(0);

  const id = useUuid();

  useEffect(() => {
    const newConfig = configureSpring(stage);

    if (!isEqual(newConfig, springConfig)) {
      setSpringConfig(newConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage]);

  useEffect(() => {
    config.current = getGoalValues(springConfig, config.current);
    completedIds.current += 1;
  }, [springConfig]);

  return {
    ...springConfig,
    config: { precision: 0.5, ...springConfig.config },
    onStart: (props) => {
      springConfig.onStart && springConfig.onStart();
      registerSpring(id);
    },
    onRest: (props) => {
      springConfig.onRest && springConfig.onRest();
      if (isEqual(config.current, props.value)) {
        // this is because trails trigger this function multiple times
        completedIds.current += 1;
        if (completedIds.current >= length) {
          completedIds.current = 0;
          onSpringComplete(id);
        }
      }
    },
  };
};
