import React, { useState, useEffect, useRef } from "react";

import useTransitionStage from "../hooks/use-transition-stage.js";

const MorphComponent = ({ morphId, onRest, children, transitionInfo = {} }) => {
  const transition = useTransitionStage();

  const [opacity, setOpacity] = useState(1);
  const { registerRef } = transition;

  const morphRef = useRef();

  const getMargins = () => {
    const style = window
      ? window.getComputedStyle(morphRef.current)
      : morphRef.current.style;

    if (style.margin) {
      return {
        top: style.margin,
        left: style.margin,
        bottom: style.margin,
        right: style.margin,
      };
    } else {
      return {
        top: style["margin-top"] || 0,
        left: style["margin-left"] || 0,
        right: style["margin-right"] || 0,
        bottom: style["margin-bottom"] || 0,
      };
    }
  };

  useEffect(() => {
    registerRef(morphId, {
      getNode: () => {
        return morphRef.current;
      },
      getMargins,
      hide: () => {
        setOpacity(0);
      },
      show: () => {
        setOpacity(1);
      },
      onRest: () => {
        onRest && onRest();
      },
      getTransitionInfo: () => transitionInfo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.cloneElement(children, {
    style: {
      ...children.props.style,
      opacity: opacity,
    },
    ref: morphRef,
  });
};

export default MorphComponent;
